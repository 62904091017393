import {
  CardCvcElement,
  CardElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import React, { useEffect, useState } from 'react';
import './card-buy-block.scss';
import Preloader from '../preloader';

const StripeForm = ({
  onSuccess,
  loadingStripe,
  setLoadingStripe,
  price,
  email,
  cardName,
  stripeError,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [cardNumber, setCardNumber] = useState(false);
  const [cardExpiry, setCardExpiry] = useState(false);
  const [cardCvc, setCardCvc] = useState(false);
  const [error, setError] = useState(null);
  const [windowWidth, setWindowWidth] = useState(null);

  const getWindowWidth = () => {
    const width = window.innerWidth;
    setWindowWidth(width);
  };

  useEffect(() => {
    getWindowWidth();
    window.addEventListener('resize', getWindowWidth);
    return () => window.removeEventListener('resize', getWindowWidth);
  }, []);

  const handleSubmit = async (event) => {
    if (price > 0) {
      setLoadingStripe(true);

      event.preventDefault();

      if (!stripe || !elements) {
        return;
      }
      setError(null);

      const cardElement = elements.getElement(CardNumberElement);

      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
      });

      const token = await stripe.createToken(cardElement);

      if (error) {
        setLoadingStripe(false);
        console.log('[error]', error);
        setError(error.message);
      } else {
        onSuccess(token);
      }
    } else {
      event.preventDefault();
      onSuccess('');
    }
  };

  const style = {
    base: {
      color: '#191919',
      fontFamily: 'Roboto,Arial,Helvetica,sans-serif',
      fontSize: windowWidth > 768 ? '18px' : '14px',
    },
  };

  return (
    <form onSubmit={handleSubmit}>
      {price > 0 ? (
        <div className="card">
          <div className="card-element card-number">
            <CardNumberElement
              options={{
                style,
                paymentRequest: stripe.paymentRequest({
                  country: 'US',
                  currency: 'usd',
                  total: {
                    label: cardName,
                    amount: price * 100,
                  },
                }),
                placeholder: 'Enter your credit card',
              }}
              onChange={(e) => setCardNumber(e.complete)}
            />
            <i className="fa fa-check-circle"></i>
          </div>
          <div className="card-element card-expiry">
            <CardExpiryElement options={{ style }} onChange={(e) => setCardExpiry(e.complete)} />
          </div>
          <div className="card-element card-cvc">
            <CardCvcElement options={{ style }} onChange={(e) => setCardCvc(e.complete)} />
          </div>
          <div className="total">Total: ${price}</div>
        </div>
      ) : (
        <div className="total">Total: Free</div>
      )}
      {!loadingStripe && stripeError && <div className="error">{stripeError}</div>}
      {error && <div className="error">{error}</div>}
      <div className="btn-block">
        {loadingStripe ? (
          <div className="loading">
            <Preloader />
            <p className="title">The magic is happening now.</p>
            <p>Your card is being created and emailed direct to you.</p>
          </div>
        ) : (
          ''
        )}
        {price > 0 ? (
          <div
            className={`total-layout ${
              cardNumber && cardExpiry && cardCvc && email ? '' : 'disabled'
            }`}
          >
            <div className="total">Total: ${price}</div>
            <button
              type="submit"
              id="stripe-test"
              className="btn btn-sm btn-fill"
              disabled={loadingStripe}
            >
              Buy card now
              <i className="fas fa-shopping-cart"></i>
            </button>
          </div>
        ) : (
          <div className={`total-layout`}>
            <div className="total">Total: Free</div>
            <button type="submit" id="stripe-test" className="btn btn-sm btn-fill">
              Get for free
              <i className="fas fa-shopping-cart"></i>
            </button>
          </div>
        )}
      </div>
    </form>
  );
};

export default StripeForm;
