import React from 'react';
import Select from 'react-select';
import CountryTelData from 'country-telephone-data';
import './download-popup.scss';

const DownloadPopup = React.forwardRef(
  (
    {
      email,
      inputChangeHandler,
      closePopup,
      isWhatsAppNotificationEnabled,
      whatsAppNotificationHandler,
      whatsAppPhoneNumberHandler,
      whatsAppPhone,
    },
    ref
  ) => {
    const codeOptions = CountryTelData.allCountries.map((item) => ({
      value: `+${item.dialCode}`,
      label: `${item.iso2.toUpperCase()} +${item.dialCode}`,
    }));

    return (
      <div ref={ref} className="popup-open upd-popup-holder confirm-popup download-popup">
        <div
          className="upd-popup-inner"
          onClick={(e) => {
            if (e.target === e.currentTarget) {
              closePopup(e);
            }
          }}
        >
          <div className="upd-popup">
            <a href="/" className="close-popup" onClick={(e) => closePopup(e)}>
              Close
            </a>
            <div className="draft-form">
              <div className="info-block">
                <h4>Download</h4>
              </div>
              <div className="stripe-holder">
                <div className="input-block">
                  <label>
                    <input
                      type="email"
                      name="email"
                      placeholder="Enter your email"
                      className="form-control"
                      value={email}
                      onChange={inputChangeHandler}
                    />
                  </label>
                </div>
                <div className="whatsapp-block">
                  <div className="content-details">
                    <input
                      className="styled-checkbox"
                      id="styled-checkbox-2"
                      type="checkbox"
                      checked={isWhatsAppNotificationEnabled}
                      onChange={whatsAppNotificationHandler}
                    />
                    <label htmlFor="styled-checkbox-2">
                      I want to receive attachments via WhatsApp
                    </label>
                  </div>
                  <div
                    className={`whatsapp-number ${isWhatsAppNotificationEnabled ? '' : 'disabled'}`}
                  >
                    <Select
                      options={codeOptions}
                      className={'select'}
                      isDisabled={!isWhatsAppNotificationEnabled}
                      styles={{
                        dropdownIndicator: () => ({
                          display: 'none',
                        }),
                        indicatorsContainer: () => ({
                          display: 'none',
                        }),
                        control: () => ({
                          border: '1px solid #707070',
                        }),
                      }}
                      onChange={(v) => whatsAppPhoneNumberHandler(v.value)}
                    />
                    <input
                      className="content-field"
                      placeholder="Enter your WhatsApp number"
                      type="text"
                      name="phone"
                      value={whatsAppPhone ? `${whatsAppPhone}` : ''}
                      disabled={!isWhatsAppNotificationEnabled}
                      onChange={(event) => whatsAppPhoneNumberHandler(event.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className={`total-layout`}>
                <div className="total">Total: Free</div>
                <button type="submit" className="btn btn-sm btn-fill">
                  Download
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
);

export default DownloadPopup;
